import { LinkBox } from "@components/web/LinkBox";
import styles from "@components/WebpImage.module.scss";
import { WebCdnImageModel } from "@reshopper/web-client";
import { pickFirstSmallestImageLargerThan as pickFirstMostSuitableImage } from "@utils/images";
import { delay } from "@utils/miscellaneous";
import { forwardRef, Ref, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { BlurhashCanvas } from "react-blurhash";

type SquareDimensionProps = {
    size: number
}

type RectangleDimensionProps = {
    width?: number,
    height?: number
}

type DimensionProps = 
    SquareDimensionProps | 
    RectangleDimensionProps;

type IndividualImageSourceProps = {
    src: string,
    blurhash?: string,
    loadingSrc?: string
};

type CdnImageSourceProps = {
    images: WebCdnImageModel[]|undefined
}

type ImageSourceProps = 
    IndividualImageSourceProps |
    CdnImageSourceProps;

type Props = {
    delay?: number,
    href?: string,
    wrapperClassName?: string,
    imageClassName?: string,
    fallbackSrc?: string,
    alt?: string,
    style?: React.CSSProperties,
    onClick?: (ev: SyntheticEvent) => void,
    onLoaded?: () => void
} & 
    DimensionProps &
    ImageSourceProps;

function getSizeFromDimensions(dimensions: DimensionProps) {
    return {
        width: !('size' in dimensions) ? dimensions.width! : dimensions.size,
        height: !('size' in dimensions) ? dimensions.height! : dimensions.size
    };
}

function getImageSourceProps(props: Props) {
    if('images' in props) {
        if(!props.images)
            return {};

        const desiredSize = getSizeFromDimensions(props);
        const suitableImage = pickFirstMostSuitableImage(
            props.images, 
            desiredSize.width, 
            desiredSize.height);
        if(!suitableImage)
            return {};

        return {
            blurhash: suitableImage.blurHash,
            src: suitableImage.url
        };
    }

    return {
        blurhash: props.blurhash,
        src: props.src,
        loadingSrc: props.loadingSrc
    };
}

export default forwardRef((props: Props, _: Ref<HTMLDivElement>) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadingImageLoaded, setIsLoadingImageLoaded] = useState(false);
    const [isErrored, setIsErrored] = useState(false);
    
    useEffect(() => {
        if(!isLoaded)
            return;

        props.onLoaded && props.onLoaded();
    }, [isLoaded]);

    const realDelay = props.delay || 0;

    const dimensionStyle = getSizeFromDimensions(props);

    const imageSourceProps = getImageSourceProps(props);
    const isFallback = useMemo(
        () => isErrored || !imageSourceProps.src,
        [isErrored, imageSourceProps.src]);
    const src = useMemo(
        () => isFallback ? 
            props.fallbackSrc :
            imageSourceProps.src, 
        [isFallback]);

    function renderLoadingImage() {
        if(isFallback)
            return null;

        if(imageSourceProps.blurhash) {
            return <div
                style={{ 
                    display: 'inline-block', 
                    height: dimensionStyle.height, 
                    width: dimensionStyle.width, 
                    overflow: 'hidden',
                    ...(props.style || {}),
                    position: 'absolute' 
                }}
            >
                <BlurhashCanvas
                    hash={imageSourceProps.blurhash}
                    width={16}
                    height={16}
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        width: '100%',
                        height: '100%'
                    }}
                />
            </div>;
        }

        if(imageSourceProps.loadingSrc) {
            return <WebpImageSource
                alt={props.alt}
                src={imageSourceProps.loadingSrc} 
                delay={realDelay}
                className={props.imageClassName + " " + styles.image + " " + (isLoadingImageLoaded && styles.loaded)}
                onError={async () => {
                    setIsLoadingImageLoaded(true);
                }}
                onLoad={async () => {
                    setIsLoadingImageLoaded(true);
                }}
                style={{
                    ...(props.style || {}),
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0
                }}
            />;
        }

        return null;
    }

    return <LinkBox 
        href={props.href || null!}
        onClick={(ev: SyntheticEvent) => {
            props.onClick && props.onClick(ev);

            if(props.onClick || props.href)
                ev.stopPropagation();
        }}
        className={styles.root + " " + props.wrapperClassName} 
        style={{
            display: 'inline-block',
            ...dimensionStyle
        }}
    >
        {renderLoadingImage()}

        <WebpImageSource 
            alt={props.alt}
            src={src || ""}
            delay={realDelay * 2}
            onError={async () => {
                setIsErrored(true);
                setIsLoaded(true);
            }}
            onLoad={async () => {
                setIsLoaded(true);
            }}
            className={props.imageClassName + " " + styles.image + " " + (isLoaded && styles.loaded)}
            style={{
                ...(props.style || {}),
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                visibility: isErrored && !props.fallbackSrc ? 
                    "hidden" :
                    "visible"
            }}
        />
    </LinkBox>;
})

function WebpImageSource(props: {
    src: string,
    delay?: number,
    style?: React.CSSProperties,
    className?: string,
    alt?: string,
    onLoad?: () => any,
    onError?: () => any
}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const propMime = getMimetypeFromUrl(props.src);

    const style: React.CSSProperties = {
        objectFit: 'cover',
        ...(props.style || {})
    };

    return <picture 
        style={{
            visibility: isLoaded ?
                'visible' :
                'hidden',
            position: 'absolute',
            objectFit: 'cover',
            ...props.style
        }}
    >
        <source 
            style={style}
            type={propMime} 
            srcSet={props.src} />
        <img 
            loading="lazy"
            onError={async () => {
                await delay(props.delay || 0);
                props.onError && props.onError();
            }}
            onLoad={async () => {
                await delay(props.delay || 0);
                setIsLoaded(true);
                props.onLoad && props.onLoad();
            }} 
            className={props.className}
            srcSet={proxifyImageUrl(props.src)}
            style={style}
            alt={props.alt}  />
    </picture>;
}

function getMimetypeFromUrl(url: string) {
    if(!url)
        return "image/jpeg";

    return url.endsWith(".webp") ?
        "image/webp" :
        "image/jpeg";
}

function proxifyImageUrl(url: string) {
    if(!url)
        return "";

    if(url.indexOf("-media.reshopper.com") === -1) {
        return url;
    }

    if(url.startsWith("https://"))
        url = url.substr("https://".length);

    return "https://cached-app.reshopper.com/media/" + encodeURIComponent(url);
}