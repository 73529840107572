import { WebCdnImageModel } from "@reshopper/web-client";
import { groupBy, keys, sortBy, values } from "lodash";

export function pickThumbnailImage(images: Array<WebCdnImageModel|undefined>|undefined) {
    if(!images)
        return null;

    return sortBy(
        images.filter(img => img && img.width === img.height),
        img => img && img.width * img.height)[0];
}

export function pickFirstSmallestImageLargerThan(images: Array<WebCdnImageModel|undefined>|undefined, width: number, height: number) {
    if(!images || images.length === 0)
        return null;

    const initialImages = values(
        groupBy(
            images, 
            img => img?.fileId))[0];
    if(!initialImages)
        return null;

    const isSquare = width === height;
    let relevantImages = initialImages.filter(x => x != null &&
        (x.width === x.height) === isSquare);

    if(relevantImages.length === 0)
        relevantImages = initialImages.filter(x => x != null);

    const sortedImages = sortBy(
        relevantImages,
        img => img && img.width * img.height);
    return sortedImages.find(x => x && x.width >= width && x.height >= height) ||
        sortedImages[sortedImages.length-1];
}

export function pickLargestImage(images: Array<WebCdnImageModel|undefined>|undefined) {
    if(!images || images.length === 0)
        return null;

    const sortedImages = sortBy(
        images,
        img => img && img.width * img.height);
    return sortedImages[sortedImages.length-1];
}

export function pickFirstThumbnailImage(images: Array<WebCdnImageModel|undefined>|undefined) {
    if(!images || images.length === 0)
        return null;

    return pickThumbnailImage(
        values(
            groupBy(
                images, 
                img => img?.fileId))[0]);
};

export function pickFirstLargestImage(images: Array<WebCdnImageModel|undefined>|undefined) {
    if(!images || images.length === 0)
        return null;

    return pickLargestImage(
        values(
            groupBy(
                images, 
                img => img?.fileId))[0]);
};

export function pickThumbnailImages(images: Array<WebCdnImageModel|undefined>|undefined) {
    if(!images)
        return [];

    var imagesById = groupBy(images, img => img?.fileId);
    return keys(imagesById)
        .map(function (key) {
            return pickThumbnailImage(imagesById[key]);
        });
};

export function pickLargestImages(images: Array<WebCdnImageModel|undefined>|undefined) {
    if(!images)
        return [];

    var imagesById = groupBy(images, img => img?.fileId);
    return keys(imagesById)
        .map(function (key) {
            return pickLargestImage(imagesById[key]);
        });
};